<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="channelTextSearchQuery"
			:search-query-label="'Channel Name' | useLabels"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeChannelTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="channelTypeid"
					label="Type"
					class="mb-2"
					:items="typeFilterItems"
					custom-sort
					@input="changeChannelTypeId($event)"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="fullAccessPermissions"
					@click="onCreateClick"
				>
					<v-icon
						left
						dark
					>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Channels' | useLabels }}
			</template>
			<template #title-action>
				<SecondaryActionButton @click="viewConsentStructure">
					View Consent Structure
				</SecondaryActionButton>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredChannels"
					sort-by="channelId"
					:sort-desc="true"
					@click:row="onEditChannel"
				>
					<template #item.channelName="{ item }">
						{{ getTruncatedText(item.channelName, 50) }}
					</template>
					<template #item.parentChannelName="{ item }">
						{{ getTruncatedText(item.parentChannelName, 50) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="(item.brandID !== 0 || (item.brandID === 0 && userHasUniversalBrand)) && fullAccessPermissions"
							:tooltip-text="'Edit Channel' | useLabels"
							@click="onEditChannel(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							:tooltip-text="'View Channel' | useLabels"
							@click="onEditChannel(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="(item.brandID !== 0 || (item.brandID === 0 && userHasUniversalBrand)) && fullAccessPermissions"
							:tooltip-text="'Delete Channel' | useLabels"
							@click.stop.prevent="onRemoveChannel(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<Modal
			v-if="channelToBeRemoved"
			width="500px"
		>
			<template #modal-title>
				Delete {{ channelToBeRemoved.channelName }}
			</template>
			<template #modal-content>
				Are you sure you wish to delete this {{ 'Channel' | useLabels }}? <br>
				You will not be able to recover it if you confirm its deletion.
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="closeDeleteChannelModal">
					Close
				</SecondaryActionButton>
				<DeleteButton @click="deleteChannel">
					Delete
				</DeleteButton>
			</template>
		</Modal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { deleteChannel } from '../../../../../shared/utils/api/channels.js'
import Modal from '../../../../../shared/components/modal.vue'
import DeleteButton from '../../../../../shared/components/delete-button.vue'
import { MANAGE_CHANNEL, CONSENT_STRUCTURE } from '../../../router/route-names.js'
import { channelTypeOptions } from './channel-type-options.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { COMPONENTS_MODULE_FULL_PERMISSIONS, CHANNELS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../shared/state/meta-data.js'
import { changeChannelTextSearchQuery, channelTextSearchQuery, changeChannelTypeId, channelTypeid } from '../../../../../shared/state/channels.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'

export default {
	components: { DeleteButton, Modal, DataTable, SectionCard, Dropdown, PrimaryActionButton, SecondaryActionButton, FilterBar, IconButton },
	props: {
		channels: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery,
			changeChannelTextSearchQuery,
			channelTextSearchQuery,
			changeChannelTypeId,
			channelTypeid,
			getTruncatedText,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			searchQuery: '',
			selectedBrandId: null,
			selectedTypeId: null,
			tableHeaders: [
				{ text: 'ID', value: 'channelId', width: '5%' },
				{ text: useLabels('Brand'), value: 'brandName', width: '15%' },
				{ text: useLabels('Channel Name'), value: 'channelName', width: '20%' },
				{ text: useLabels('Parent Channel Name'), value: 'parentChannelName', width: '20%' },
				{ text: 'Channel Type', value: 'typeDescription', width: '15%' },
				{ text: 'Created Date', value: 'createdDate', width: '15%' },
				{ text: 'Action', value: 'action', width: '10%', sortable: false }
			],
			channelToBeRemoved: null,
			channelTypeOptions
		}
	},
	computed: {
		...mapGetters('auth', ['userHasUniversalBrand']),
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		filteredChannels () {
			return this.channels.filter(({ channelName, channelType, brandID, metaData }) => {
				let check = true
				if (channelTextSearchQuery.value) check = channelName.toLowerCase().includes(channelTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandID
				if (channelTypeid.value !== null) check = check && channelTypeid.value === channelType?.typeID
				if (this.selectedMetaDataKey !== null) check = check && metaData.some(meta => meta.metaDataKeyId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				return check
			})
		},
		fullAccessPermissions () {
			return this.checkPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.checkPermission(CHANNELS_FULL_PERMISSIONS)
		},
		typeFilterItems () {
			const PARENT_CHANNEL = 1
			const CHILD_CHANNEL = 2
			const DOUBLE_OPT_IN_CHANNEL = 3
			return [
				{
					text: 'View All',
					value: null
				},
				{
					text: useLabels('Child Channel'),
					value: CHILD_CHANNEL
				},
				{
					text: useLabels('Double Opt In Channel'),
					value: DOUBLE_OPT_IN_CHANNEL
				},
				{
					text: useLabels('Parent Channel'),
					value: PARENT_CHANNEL
				}
			]
		}
	},
	methods: {
		onCreateClick () {
			this.$router.push({ name: MANAGE_CHANNEL })
		},
		onEditChannel (channelToEdit) {
			this.$router.push({ name: MANAGE_CHANNEL, params: { channelToEdit } })
		},
		onRemoveChannel (channel) {
			this.channelToBeRemoved = channel
		},
		closeDeleteChannelModal () {
			this.channelToBeRemoved = null
		},
		async deleteChannel () {
			await deleteChannel(this.channelToBeRemoved.channelId)
			showSnackbar(useLabels('This channel has been removed'))
			this.closeDeleteChannelModal()
			this.$emit('load-channels')
		},
		clearFilters () {
			this.selectedTypeId = null
		},
		viewConsentStructure () {
			this.$router.push({
				name: CONSENT_STRUCTURE,
				params: {
					accessedVia: 'channels'
				}
			})
		}
	}
}
</script>
