<template>
	<ChannelsLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<ChannelsTab
						:channels="channels"
						@load-channels="loadChannels"
					/>
				</template>
				<template #1>
					<ChannelTranslationsTab
						:channels="channels"
						:languages="languages"
						:previously-selected-language="previouslySelectedLanguage"
						@load-channels="loadChannels"
					/>
				</template>
			</TabItems>
		</template>
	</ChannelsLayout>
</template>

<script>
import { ref } from '@vue/composition-api'
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import ChannelsTab from './channels-tab.vue'
import ChannelsLayout from './channels-layout.vue'
import ChannelTranslationsTab from './channel-translations-tab.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../shared/state/meta-data.js'
export const tab = ref('0')
export default {
	components: { ChannelTranslationsTab, ChannelsLayout, ChannelsTab, TabItems, Tabs },
	props: { previouslySelectedLanguage: Number },
	setup () {
		return {
			tab,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			tabs: [{ title: useLabels('Channels'), slot: '0' }, { title: useLabels('Channel Translations'), slot: '1' }],
			channels: [],
			channelCategories: [],
			languages: []
		}
	},
	created () {
		this.loadChannels()
	},
	methods: {
		async loadChannels () {
			const { data: { channels, channelCategories } } = await getPreferenceChannels()
			this.channelCategories = channelCategories
			this.channels = channels
				.filter(channel => !channel.channelType?.doubleOptInRelatedChannelID)
				.map(channel => ({
					...channel,
					parentChannelName: channel.channelType?.parentChannelName,
					typeDescription: channel.channelType?.typeDescription,
					allowUniversalConsent: channel.overrideUniversalConsent
				}))
		}
	}
}
</script>
