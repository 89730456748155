<template>
	<div class="cassie-vertical-md pt-4">
		<FilterBar
			:search-query.sync="channelTextSearchQuery"
			search-query-label="Channel Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeChannelTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="channelTranslationStatusId"
					:items="showTranslatedOnlyOptions"
					custom-sort
					label="Translation Status"
					@input="changeChannelTranslationStatusId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Channel Translations' | useLabels }}
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredChannelTranslations"
					sort-by="channelId"
					sort-desc
					@click:row="onEditChannelTranslation"
				>
					<template #item.channelName="{ item }">
						{{ getTruncatedText(item.channelName, 50) }}
					</template>
					<template #item.translatedChannelName="{ item }">
						{{ item.translatedChannelName ? getTruncatedText(item.translatedChannelName, 50) : item.translatedChannelName }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="(item.brandID !== 0 || (item.brandID === 0 && userHasUniversalBrand)) && fullAccessPermissions"
							:tooltip-text="'Edit Channel Translation' | useLabels"
							@click="onEditChannelTranslation(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							:tooltip-text="'View Channel Translation' | useLabels"
							@click="onEditChannelTranslation(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="item.translatedChannelName && (item.brandID !== 0 || (item.brandID === 0 && userHasUniversalBrand)) && fullAccessPermissions"
							:tooltip-text="'Delete Channel Translation' | useLabels"
							@click.stop.prevent="onRemoveChannelTranslation(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="channelTranslationToBeRemoved"
			:entity-name="channelTranslationToBeRemoved.translatedChannelName"
			entity-type="Channel Translation"
			@close="channelTranslationToBeRemoved = null"
			@delete="deleteChannelTranslation"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { deleteChannelTranslation } from '../../../../../shared/utils/api/channels.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { MANAGE_CHANNEL_TRANSLATION } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../shared/state/meta-data.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { changeChannelTextSearchQuery, channelTextSearchQuery, changeChannelTranslationStatusId, channelTranslationStatusId } from '../../../../../shared/state/channels.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'
import { COMPONENTS_MODULE_FULL_PERMISSIONS, CHANNELS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: { DataTable, SectionCard, ConfirmDeleteModal, FilterBar, Dropdown, IconButton },
	props: {
		channels: {
			type: Array,
			default: () => []
		},
		previouslySelectedLanguage: Number
	},
	setup () {
		return {
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery,
			channelTextSearchQuery,
			changeChannelTextSearchQuery,
			changeChannelTranslationStatusId,
			channelTranslationStatusId,
			getTruncatedText,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId
		}
	},
	data () {
		return {
			showTranslatedOnly: null,
			selectedBrandId: null,
			selectedLanguageId: null,
			channelTranslationToBeRemoved: null,
			assignedLanguages: []
		}
	},
	computed: {
		...mapGetters('auth', ['userHasUniversalBrand']),
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		fullAccessPermissions () {
			return this.checkPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.checkPermission(CHANNELS_FULL_PERMISSIONS)
		},
		channelTranslations () {
			return this.channels
				.map(channel => {
					const channelTranslation = channel.channelTranslations.find(({ languageID }) => languageID === this.selectedLanguageIdOrDefault)
					const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
					const translatedStatements = []
					channel.statements?.forEach(statement => {
						const translation = statement.liveStatements?.find(({ languageId }) => languageId === this.selectedLanguageIdOrDefault) ??
							statement.draftStatements?.find(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
						if (translation) translatedStatements.push(translation)
					})
					return {
						...channel,
						translatedChannelName: channelTranslation?.translatedChannelName,
						channelTranslationID: channelTranslation?.channelTranslationID,
						languageName: language?.text,
						languageId: language?.value,
						statementsTranslated: `${translatedStatements?.length}/${channel.statements.length}`
					}
				})
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			if (this.previouslySelectedLanguage != null) return this.previouslySelectedLanguage
			return this.assignedLanguagesItems[0]?.value
		},
		tableHeaders () {
			return [
				{ value: 'channelId', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'channelName', text: useLabels('Channel Name'), width: '20%' },
				{ value: 'translatedChannelName', text: useLabels('Translated Channel Name'), width: '20%' },
				{ value: 'statementsTranslated', text: useLabels('Statements') + ' Translated', width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%', sortable: false }
			]
		},
		filteredChannelTranslations () {
			return this.channelTranslations.filter(({ channelName, brandID, translatedChannelName, metaData }) => {
				let check = true
				if (channelTextSearchQuery.value) check = channelName.toLowerCase().includes(channelTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandID
				if (channelTranslationStatusId.value !== null) check = check && (channelTranslationStatusId.value ? Boolean(translatedChannelName) : !translatedChannelName)
				if (this.selectedMetaDataKey !== null) check = check && metaData.some(meta => meta.metaDataKeyId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				return check
			})
		},
		showTranslatedOnlyOptions () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		}
	},
	created () {
		this.loadLanguages()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		async deleteChannelTranslation () {
			await deleteChannelTranslation(this.channelTranslationToBeRemoved.channelTranslationID)
			showSnackbar(useLabels('This channel translation has been removed'))
			this.$emit('load-channels')
			this.channelTranslationToBeRemoved = null
		},
		onEditChannelTranslation (channelToEdit) {
			this.$router.push({
				name: MANAGE_CHANNEL_TRANSLATION,
				params: {
					channelToEdit
				}
			})
		},
		onRemoveChannelTranslation (channelTranslation) {
			this.channelTranslationToBeRemoved = channelTranslation
		},
		clearFilters () {
			changeChannelTranslationStatusId(null)
		}
	}
}
</script>
